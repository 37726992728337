<template>
    <div>
        <wizard-attachments
            @uploadedFiles="uploadedFiles = $event"
            @goToNextStep="$emit('goToNextStep')"
            @goToPrevStep="$emit('goToPrevStep', $event)"
        />
    </div>
</template>

<script>
import WizardAttachments from '@/components/wizard/WizardAttachmentsWithSteps'

export default {
    name: 'Index',
    metaInfo: {
        title: 'Wizard Documents'
    },
    components: {
        WizardAttachments
    },
    data() {
        return {
            uploadedFiles: null
        }
    }
}
</script>
